import { WAITING_MESSAGE } from "@/localizations/staticPageErrors";
import { ProgressIcon } from "auth-ui-client";
import React from "react";

import styles from "./Loader.module.scss";
import { LoaderProps } from "./Loader.types";
import classNames from "classnames";

export const Loader: React.FC<LoaderProps> = ({ isLoading, children }) => {
  return (
    <div className={styles.root}>
      {isLoading && (
        <div className={styles.loader}>
          <ProgressIcon className={styles.spin} />

          {WAITING_MESSAGE}
        </div>
      )}

      <div className={classNames(styles.content, isLoading && styles.loading)}>{children}</div>
    </div>
  );
};
