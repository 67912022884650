import React from "react";
import styles from "./FormItem.module.scss";

import { Form, FormItemProps } from "antd";
import classNames from "classnames";

export function FormItem<Values = any>({ children, className, ...props }: FormItemProps<Values>): React.ReactElement {
  return (
    <Form.Item {...props} className={classNames(styles.root, className)}>
      {children}
    </Form.Item>
  );
}
