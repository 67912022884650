import { ERROR_PAGE_MESSAGE } from "auth-ui-client";
import React, { useEffect, useRef } from "react";
import { FeedbackProps } from "./Feedback.types";
import { Alert } from "../Alert";

export const Feedback: React.FC<FeedbackProps> = ({ feedbackMessages, setFeedbackMessages, closable }) => {
  const deleteMessage = (messageKey: string) => {
    if (feedbackMessages.find((item) => item.key === messageKey)?.text === ERROR_PAGE_MESSAGE) {
      window.location.href = `${window.location.origin}${window.location.pathname}?service=${
        Object.fromEntries(new URL(window.location.toString()).searchParams.entries()).service
      }`;
    } else {
      setFeedbackMessages && setFeedbackMessages(feedbackMessages.filter((item) => item.key !== messageKey));
    }
  };

  const feedbackContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    feedbackContainer.current?.scrollIntoView();
  }, [feedbackMessages]);

  if (!feedbackMessages.length) return null;

  return (
    <div className="mb-3" ref={feedbackContainer}>
      {feedbackMessages.map((item) => (
        <Alert
          key={item.key}
          message={item}
          onCloseBtn={() => {
            deleteMessage(item.key);
          }}
          closable={closable}
        />
      ))}
    </div>
  );
};
