import React, { FC, useRef } from "react";
import cn from "classnames";
import { Input as AntInput, InputRef } from "antd";
import { useAutofillEffect } from "auth-ui-client";
import { InputProps } from "./Input.types";
import styles from "./Input.module.scss";
import { noop } from "utils/common.utils";

export const Input: FC<InputProps> = ({
  id,
  name,
  value,
  setter,
  placeholder = "",
  testId,
  autoComplete,
  className,
  type,
  suffix,
  prefix,
  maxLength,
  onChange,
  role,
  title,
}) => {
  const field = useRef<InputRef | null>(null);

  useAutofillEffect(field, setter || noop);

  return (
    <AntInput
      id={id}
      ref={(hRef) => {field.current = hRef}}
      title={title}
      placeholder={placeholder}
      name={name}
      className={cn(styles.root, prefix || suffix ? styles.withPrefixSuffix : styles.withoutPrefixSuffix, className)}
      maxLength={maxLength}
      role={role}
      type={type}
      value={value}
      onChange={onChange}
      autoComplete={autoComplete}
      prefix={prefix}
      suffix={suffix}
      data-testid={testId}
    />
  );
};
