import React from "react";
import { useProcessContext } from "auth-ui-client";
import { Loader } from "../Loader";
import { Meta } from "../Meta";
import { DepartmentLogos } from "../DepartmentLogos";
import classNames from "classnames";
import { Footer } from "../Footer";
import { TECH_SUPPORT_PREFIX } from "@/localizations/staticPageErrors";
import { BasePageProps } from "./Layout.types";
import styles from "./Layout.module.scss";
import { FormContainer } from "../FormContainer";

export const Layout: React.FC<BasePageProps> = ({
  applicationTitle,
  children,
  commonProps,
  className,
  widthPreset = "w360",
}) => {
  const isSubmitting = useProcessContext()[0];

  const backgroundUrl = commonProps?.backgroundUrl;
  const faviconUrl = commonProps?.faviconUrl || "./images/favicon.ico";

  return (
    <Meta applicationTitle={applicationTitle} faviconUrl={faviconUrl}>
      <div
        className={classNames(styles.root, styles[widthPreset], className)}
        style={backgroundUrl ? { background: `url(${backgroundUrl})`, backgroundSize: `cover` } : {}}
      >
        <div className="tablet:py-10 pt-0 pb-6">
          <Loader isLoading={isSubmitting}>
            <FormContainer>{children}</FormContainer>
          </Loader>

          <DepartmentLogos className={classNames("mt-6 tablet:mt-10")} />

          {commonProps?.techSupport && (
            <Footer
              techSupportContacts={commonProps.techSupport}
              techSupportLocalization={{
                techSupportPrefix: TECH_SUPPORT_PREFIX,
                techSupportName: commonProps.techSupport.techSupportUrl,
              }}
            />
          )}
        </div>
      </div>
    </Meta>
  );
};
