import React from "react";
import Head from "next/head";
import { MetaProps } from "./Meta.types";

export const Meta: React.FC<MetaProps> = ({ applicationTitle, faviconUrl, children }) => {
  return (
    <>
      <Head>
        <title>{applicationTitle}</title>

        <link rel="shortcut icon" href={faviconUrl} />
      </Head>

      {children}
    </>
  );
};
