import React from "react";
import classNames from "classnames";
import styles from "./Alert.module.scss";
import { AlertProps } from "./Alert.types";
import Image from "next/image";
import { CLOSE_ICON } from "./Alert.consts";

export const Alert: React.FC<AlertProps> = (props) => {
  const {
    message: { text, level, className },
    closable = true,
  } = props;

  return (
    <div role="alert" className={classNames(styles.root, styles[level], closable && styles.withCloseIcon, className)}>
      {typeof text === "string" ? <span>{text}</span> : text()}

      {closable && (
        <Image
          src={CLOSE_ICON[level]}
          className={styles.close}
          onClick={props.onCloseBtn}
          data-testid="CloseAlertButton"
          alt=""
        />
      )}
    </div>
  );
};
