import React from "react";
import logo from "./logo.svg";
import Image from "next/image";
import { ComponentCommonProps } from "types/common.types";

import classNames from "classnames";

export const Logo: React.FC<ComponentCommonProps> = React.memo(({ className }) => {
  return (
    <div className={classNames("flex justify-center", className)}>
      <Image priority src={logo} alt="" />
    </div>
  );
});
