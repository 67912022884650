import React from "react";
import styles from "./FormContainer.module.scss";
import classNames from "classnames";
import { FormContainerProps } from "./FormContainer.types";
import { Logo } from "../Logo";

export const FormContainer: React.FC<FormContainerProps> = ({ children, className, logoClassName }) => {
  return (
    <div className={classNames(styles.root, "p-6", className)}>
      <Logo className={classNames("mt-6 mb-12", logoClassName)} />

      {children}
    </div>
  );
};
