import { FieldNames } from "auth-ui-client";

export class KigFieldNames implements FieldNames {
  readonly STORAGE_PLACEHOLDER = "Хранилище";
  readonly STORAGE_LABEL = "Хранилище";

  readonly LOGIN_PLACEHOLDER = "Логин";
  readonly LOGIN_LABEL = "Логин";

  readonly MOBILE_PLACEHOLDER = "Введите телефон";
  readonly MOBILE_LABEL = "Телефон";

  readonly EMAIL_PLACEHOLDER = "login@domain.ru";
  readonly EMAIL_LABEL = "Электронная почта";

  readonly ACCOUNT_PLACEHOLDER = "Выберите пользователя";
  readonly ACCOUNT_LABEL = "Пользователь";

  readonly ASSIGNMENT_PLACEHOLDER = "Выберите кадровое назначение";
  readonly ASSIGNMENT_LABEL = "Кадровое назначение";

  readonly PASSWORD_PLACEHOLDER = "Пароль";
  readonly PASSWORD_LABEL = "Пароль";

  readonly OLD_PASSWORD_PLACEHOLDER = "Введите текущий пароль";
  readonly OLD_PASSWORD_LABEL = "Пароль";

  readonly NEW_PASSWORD_PLACEHOLDER = "Введите новый пароль";
  readonly NEW_PASSWORD_LABEL = "Новый пароль";

  readonly CONFIRM_NEW_PASSWORD_PLACEHOLDER = "Подтвердите новый пароль";
  readonly CONFIRM_NEW_PASSWORD_LABEL = "Подтвердите новый пароль";

  readonly CAPTCHA_CODE_UPDATE = "Обновить код";
  readonly CAPTCHA_PLACEHOLDER = "Введите код";
  readonly CAPTCHA_LABEL = "Код проверки";
  readonly REMEMBER_ME = "Запомнить меня";

  readonly LOGIN_BTN_SIGN = "Войти";
  readonly CHANGE_PASSWORD_BTN_SIGN = "Изменить пароль";
  readonly START_RECOVERY_BTN_SIGN = "Отправить";
  readonly RECOVER_BTN_SIGN = "Сменить пароль";
  readonly SET_PASSWORD_BTN_SIGN = "Установить пароль";
  readonly ESIA_BTN_SIGN = "Вход через";

  readonly RECOVERY_LINK = "Восстановить пароль";
  readonly LOGIN_LINK = "Отменить";
  readonly REGISTRATION_LINK = "Регистрация";
  readonly CHANGE_PASSWORD_LINK = "Изменить пароль";
  readonly GO_TO_CHANGE_PASSWORD_LINK = "Перейти к смене пароля";

  readonly LOGIN_TAB_NAME = "Логин";
  readonly PHONE_TAB_NAME = "Телефон";
  readonly EMAIL_TAB_NAME = "Почта";
  readonly ACCOUNT_TAB_NAME = "Сотрудник";

  readonly LOGIN_TAB_TEST_ID = "LoginTabLink";
  readonly PHONE_TAB_TEST_ID = "MobileTabLink";
  readonly EMAIL_TAB_TEST_ID = "EmailTabLink";
  readonly ACCOUNT_TAB_TEST_ID = "SelectedAccountTabLink";

  readonly CHANGE_PASSWORD_FORM_TITLE = "Изменение пароля";
  readonly START_RECOVERY_FORM_TITLE = "Восстановление пароля";
  readonly RECOVER_FORM_TITLE = "Восстановление пароля";
  readonly SET_PASSWORD_FORM_TITLE = "Установка пароля";

  fieldName(
    fieldCode:
      | "STORAGE"
      | "LOGIN"
      | "EMAIL"
      | "MOBILE"
      | "ACCOUNT"
      | "PASSWORD"
      | "OLD_PASSWORD"
      | "NEW_PASSWORD"
      | "REPEAT_PASSWORD"
      | "CAPTCHA"
      | "ASSIGNMENT"
  ): string {
    switch (fieldCode) {
      case "STORAGE":
        return KigFieldNames.get().STORAGE_LABEL;
      case "LOGIN":
        return KigFieldNames.get().LOGIN_LABEL;
      case "EMAIL":
        return KigFieldNames.get().EMAIL_LABEL;
      case "MOBILE":
        return KigFieldNames.get().MOBILE_LABEL;
      case "ACCOUNT":
        return KigFieldNames.get().ACCOUNT_LABEL;
      case "PASSWORD":
        return KigFieldNames.get().PASSWORD_LABEL;
      case "OLD_PASSWORD":
        return KigFieldNames.get().OLD_PASSWORD_LABEL;
      case "NEW_PASSWORD":
        return KigFieldNames.get().NEW_PASSWORD_LABEL;
      case "REPEAT_PASSWORD":
        return KigFieldNames.get().CONFIRM_NEW_PASSWORD_LABEL;
      case "CAPTCHA":
        return KigFieldNames.get().CAPTCHA_LABEL;
      case "ASSIGNMENT":
        return KigFieldNames.get().ASSIGNMENT_LABEL;
    }
  }

  private static instance: KigFieldNames;

  static get(): KigFieldNames {
    if (!KigFieldNames.instance) {
      KigFieldNames.instance = new KigFieldNames();
    }
    return KigFieldNames.instance;
  }
}

export const kigFieldNames = KigFieldNames.get();
