import React, { FC } from "react";
import Image from "next/image";
import moscow from "./moscow.svg";
import moscowEconomy from "./moscowEconomy.svg";
import economyDepartament from "./economyDepartament.svg";
import dit from "./dit.svg";
import classNames from "classnames";
import { ComponentCommonProps } from "types/common.types";

export const DepartmentLogos: FC<ComponentCommonProps> = React.memo(({ className }) => {
  return (
    <div className={classNames("flex justify-center", className)}>
      <div className="inline-flex gap-x-8">
        <Image src={moscow} alt="" />

        <Image src={moscowEconomy} alt="" />

        <Image src={economyDepartament} alt="" />

        <Image src={dit} alt="" />
      </div>
    </div>
  );
});
