import React from "react";
import { ButtonLinkProps } from "./ButtonLink.types";
import styles from "./ButtonLink.module.scss";

export const ButtonLink: React.FC<ButtonLinkProps> = ({ onClick, url, testId, children }) => {
  const props = {
    "data-testid": testId,
    onClick,
    className: styles.root,
  };

  return url ? (
    <a href={url} {...props}>
      {children}
    </a>
  ) : (
    <span tabIndex={0} {...props}>
      {children}
    </span>
  );
};
