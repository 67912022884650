import closeError from "./close_error.svg";
import closeWarning from "./close_warning.svg";
import closeSuccess from "./close_success.svg";
import { ImageProps } from "next/image";

export const CLOSE_ICON: Record<string, ImageProps["src"]> = {
  error: closeError,
  warning: closeWarning,
  success: closeSuccess,
};
