import React from "react";
import { FooterProps } from "./Footer.types";
import styles from "./Footer.module.scss";
import classNames from "classnames";

export const Footer: React.FC<FooterProps> = ({ techSupportContacts, techSupportLocalization, className }) => {
  const { techSupportUrl, techSupportEmail, techSupportPhone } = techSupportContacts;

  const { techSupportPrefix, techSupportName } = techSupportLocalization;

  const hasTechSupportInfo =
    techSupportContacts.techSupportUrl || techSupportContacts.techSupportEmail || techSupportContacts.techSupportPhone;

  if (!hasTechSupportInfo) return <></>;

  return (
    <div className={classNames(styles.root, "flex flex-col items-center tablet:mt-10 mt-6 gap-y-2", className)}>
      <span>{techSupportPrefix}</span>

      {techSupportUrl ? (
        <a target="_blank" rel="noreferrer" href={techSupportUrl} data-testid="TechSupportUrlLink">
          {techSupportName}
        </a>
      ) : (
        <span>
          {techSupportName}
          {techSupportName && (techSupportEmail || techSupportPhone) ? ": " : ""}
        </span>
      )}

      <div className="flex flex-wrap items-center gap-x-2">
        {techSupportPhone && (
          <a href={`tel:+${techSupportPhone.replace(/\D/gi, "")}`} data-testid="TechSupportPhoneLink">
            {techSupportPhone}
          </a>
        )}

        {techSupportEmail && <span className={styles.dot} />}

        {techSupportEmail && (
          <a href={`mailto:${techSupportEmail}`} className={styles.email} data-testid="TechSupportEmailLink">
            {techSupportEmail}
          </a>
        )}
      </div>
    </div>
  );
};
